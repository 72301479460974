document.querySelectorAll('.dropdown-toggle').forEach(item => {
    item.addEventListener('click', event => {

        if (event.target.classList.contains('dropdown-toggle')) {
            event.target.classList.toggle('toggle-change');
        } else if (event.target.parentElement.classList.contains('dropdown-toggle')) {
            event.target.parentElement.classList.toggle('toggle-change');
        }
    })
});

/**** password visibility ****/

function show_hide_password(target) {
    var input = $(target).parent().find('input');
    if (input.attr('type') == 'password') {
        $(target).addClass('view');
        input.attr('type', 'text');
    } else {
        $(target).removeClass('view');
        input.attr('type', 'password');
    }
}

/****  otp verification ****/

let digitValidate = function(ele) {
    ele.value = ele.value.replace(/[^0-9]/g, '');
}

function otpKeyup(current, next, prev = null) {
    let e = window.event;
    let inputValue = current.value.trim();
    if (!/^\d$/.test(inputValue)) {
        current.value = ''
        if(prev && e.keyCode === 8){
            document.getElementById(prev).focus();
            document.getElementById(prev).select();
        }
        return;
    }
    if (inputValue && next && e.keyCode !== 8) {
        document.getElementById(next).focus();
        document.getElementById(next).select();
    }
    if((!inputValue || e.keyCode === 8 || e.keyCode === 37) && prev){
        document.getElementById(prev).focus();
        document.getElementById(prev).select();
    }
}

document.addEventListener('click', function(e){
    if(document.querySelector('.hamburger-init')){
        if(e.target.closest('#header-navbar') == null && e.target.closest('.navbar-toggler') == null){
            document.querySelector('#header-navbar').classList.remove('show');
            document.querySelector('.hamburger-init').checked = false;
        }
        else if(e.target.closest('.navbar-toggler') != null){
            document.querySelector('#header-navbar').classList.toggle('show');
            document.querySelector('.hamburger-init').checked = document.querySelector('#header-navbar').classList.contains('show');
        }

        if((e.target.closest('.nav-link') != null || e.target.classList.contains('dropdown-item')) && e.target.classList.contains('dropdown-toggle') === false){
            document.querySelector('#header-navbar').classList.remove('show');
            document.querySelector('.hamburger-init').checked = false;
        }
    }
})
